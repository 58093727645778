import {
  Box,
  Container,
  Flex,
  Grid,
  Text,
  Image,
  Heading,
} from "@theme-ui/components"
import React from "react"
import { useCompany } from "../hooks/useCompany"
import { useFooter } from "../hooks/useFooter"
import { MagicLink } from "../utils/magicLink"
import { useSocial } from "../hooks/useSocial"
import { useLocation } from "../hooks/useLocation"
import { i18nContext } from "../context/i18nContext"
import { Facebook, Instagram, Twitter, Youtube, Linkedin } from "react-feather"
import Logo from "../images/logo.svg"
import Mail from "../images/mail.inline.svg"
import Phone from "../images/phone.inline.svg"
import Pin from "../images/pin.inline.svg"

const Footer = () => {
  const footer = useFooter()
  const company = useCompany()
  const social = useSocial()
  const locations = useLocation()

  footer.map(footerItem => {
    footerItem.treeChildren.sort((a, b) => a.position - b.position)
    footerItem.treeChildren.map(footerItem => {
      if (footerItem.treeChildren.length > 0) {
        footerItem.treeChildren.sort((a, b) => a.position - b.position)
      }
    })
  })

  return (
    <Box as="footer" sx={{ backgroundColor: "dark", py: 5, color: "light" }}>
      <i18nContext.Consumer>
        {t => (
          <>
            <Container>
              {company && (
                <Grid columns={[1]} gap={[4]}>
                  {locations.map(location => (
                    <Grid
                      columns={["1fr", "1fr 1fr 1fr"]}
                      sx={{
                        p: { fontSize: 1 },
                        "*:first-child": { mt: 0 },
                      }}
                    >
                      {location.email && (
                        <Grid
                          columns={["1fr"]}
                          sx={{
                            textAlign: "center",
                            pb: [2],
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          gap={[0]}
                        >
                          <Box sx={{ pb: [2] }}>
                            <Mail />
                          </Box>
                          <Box
                            as="div"
                            sx={{ p: { m: [0, 0, 0] } }}
                            dangerouslySetInnerHTML={{ __html: location.email }}
                          />
                          <Box sx={{ pt: [2] }}>Mail</Box>
                        </Grid>
                      )}
                      {location.streetAddress && (
                        <Grid
                          columns={["1fr"]}
                          sx={{
                            textAlign: "center",
                            pb: [2],
                            fontSize: [1],
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          gap={[0]}
                        >
                          <Box sx={{ pb: [2] }}>
                            <Pin />
                          </Box>
                          <Box sx={{ color: "primary" }}>
                            <Box>{location.streetAddress}</Box>
                            <Box>
                              {location.postalCode} {location.addressLocality}{" "}
                              {location.addressRegion}
                            </Box>
                          </Box>
                          <Box sx={{ pt: [2] }}>Address</Box>
                        </Grid>
                      )}
                      {location.telephone && (
                        <Grid
                          columns={["1fr"]}
                          sx={{
                            textAlign: "center",
                            pb: [2],
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          gap={[0]}
                        >
                          <Box sx={{ pb: [2] }}>
                            <Phone />
                          </Box>
                          <Box
                            as="div"
                            sx={{ p: { m: [0, 0, 0], fontSize: [1] } }}
                            dangerouslySetInnerHTML={{
                              __html: location.telephone,
                            }}
                          />
                          <Box sx={{ pt: [2] }}>Phone</Box>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                  {footer.map(column => (
                    <List key={column.id}>
                      {column.treeChildren.map(link => (
                        <Item>
                          <Text
                            sx={{ fontWeight: "bold", mb: 2, display: "block" }}
                          >
                            {link.link ? (
                              <MagicLink item={link.link} />
                            ) : (
                              link.anchor
                            )}
                          </Text>
                          <List key={link.id}>
                            {link.treeChildren.map(link => (
                              <Item>
                                {link.link ? (
                                  <MagicLink item={link.link} />
                                ) : (
                                  link.anchor
                                )}
                              </Item>
                            ))}
                          </List>
                        </Item>
                      ))}
                    </List>
                  ))}
                </Grid>
              )}
            </Container>
            <Container sx={{ py: 0 }}>
              <Flex
                sx={{
                  display: "flex",
                  py: 1,
                  justifyContent: "space-between",
                  alignContent: "center",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <Box>
                  {social && (
                    <Grid columns={[4]} gap={[4]} sx={{ mt: [2, 0] }}>
                      {social.facebook && (
                        <Box>
                          <MagicLink target="_blank" href={social.facebook}>
                            <Facebook color="#FFF" />
                          </MagicLink>
                        </Box>
                      )}
                      {social.instagram && (
                        <Box>
                          <MagicLink target="_blank" href={social.instagram}>
                            <Instagram color="#FFF" />
                          </MagicLink>
                        </Box>
                      )}
                      {social.linkedin && (
                        <Box>
                          <MagicLink target="_blank" href={social.linkedin}>
                            <Linkedin color="#FFF" />
                          </MagicLink>
                        </Box>
                      )}
                      {social.youtube && (
                        <Box>
                          <MagicLink target="_blank" href={social.youtube}>
                            <Youtube color="#FFF" />
                          </MagicLink>
                        </Box>
                      )}
                    </Grid>
                  )}
                </Box>
                <Box
                  sx={{
                    span: { color: "primary" },
                    fontWeight: "400",
                    fontSize: [1],
                    pt: [3, 0],
                    fontFamily: "Teko",
                  }}
                  dangerouslySetInnerHTML={{ __html: t.copyright }}
                ></Box>
              </Flex>
              <Box>
                {company && (
                  <Text
                    as="p"
                    sx={{
                      fontSize: [0],
                      color: "primary",
                      textAlign: "center",
                      m: ["0 auto"],
                    }}
                  >
                    © 2022 {company.legalName} |
                    {company.vatId && " VAT " + company.vatId}{" "}
                    {company.vatId && " S.C. " + company.shareCapital + "€"}{" "}
                    {company.registrationNumber &&
                      "- REA " + company.registrationNumber}{" "}
                    {company.addresses[0] &&
                      company.addresses[0].streetAddress &&
                      "- Registered Office: " + company.addresses[0].streetAddress}
                    {company.addresses[0] &&
                      company.addresses[0].postalCode &&
                      ", " + company.addresses[0].postalCode}{" "}
                    {company.addresses[0] &&
                      company.addresses[0].addressLocality &&
                      ", " + company.addresses[0].addressLocality}{" "}
                    {company.addresses[0] &&
                      company.addresses[0].addressRegion &&
                      ", " + company.addresses[0].addressRegion}{" "}
                    {company.addresses[0] &&
                      company.addresses[0].addressCountry &&
                      "- " + company.addresses[0].addressCountry}{" "}
                    - All rights reserved
                  </Text>
                )}
                <Text
                  as="p"
                  sx={{
                    fontSize: [0],
                    color: "primary",
                    textAlign: "center",
                    m: ["0 auto"],
                  }}
                >
                  Our website only uses technical cookies to ensure the correct
                  functioning of procedures and to improve the user experience
                  of online applications.
                </Text>
              </Box>
            </Container>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mb: 4,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return <Box {...props} as="li" sx={{ mb: 2, "&:last-child": { mb: 0 } }} />
}

export default Footer
