import React from "react"
import {
  getArticleCategoryPath,
  getArticlePath,
  getNewsPath,
  getPagePath,
  getProductPath,
  getReferencesPath,
} from "./path"
import { InboundLink, OutboundLink } from "../components/link"

const MagicLink = props => {
  const children = props.children
  const allCategories = props.categories
  const productFamily = props.productFamily
  const productCategory = props.productCategory
  const item = props.item
  
  if (item) {
    if (item.link) {
      const locale = props.locale ? props.locale : item.locale

      switch (item.link.model.apiKey) {
        case "product":
          return (
            <InboundLink
              to={getProductPath(
                item.link,
                locale,
                productCategory,
                productFamily
              )}
              {...props}
            >
              {!children ? item.anchor : children}
            </InboundLink>
          )
        case "page":
          return (
            <InboundLink to={getPagePath(item.link, locale)} {...props}>
              {!children ? item.anchor : children}
            </InboundLink>
          )
        case "news_page":
          return (
            <InboundLink
              to={getNewsPath(locale)}
              variant={props.variant}
              sx={props.sx}
            >
              {!children ? item.anchor : children}
            </InboundLink>
          )

        case "article":
          return (
            <InboundLink
              to={getArticlePath(item.link, locale)}
              as={props.as}
              variant={props.variant}
              sx={props.sx}
            >
              {!children ? item.anchor : children}
            </InboundLink>
          )
        case "references_page":
          return (
            <InboundLink
              to={getReferencesPath(locale)}
              variant={props.variant}
              sx={props.sx}
            >
              {!children ? item.anchor : children}
            </InboundLink>
          )
        case "article_category":
          return (
            <InboundLink
              to={getArticleCategoryPath(item.link, locale)}
              as={props.as}
              variant={props.variant}
              sx={props.sx}
            >
              {!children ? item.anchor : children}
            </InboundLink>
          )
        default:
          return null
      }
    }
    return (
      <OutboundLink href={item.url} {...props}>
        {!children ? item.anchor : children}
      </OutboundLink>
    )
  } else {
    return <OutboundLink href="" {...props}>
      {!children ? item.anchor : children}
    </OutboundLink>
  }
}

export { MagicLink }
