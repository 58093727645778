import { Box, Container, Flex } from "@theme-ui/components"
import React, { useState } from "react"
import { useMenu } from "../hooks/useMenu"
import { getHomePath } from "../utils/path"
import { Link } from "./link"
import { MagicLink } from "../utils/magicLink"
import { LanguageSwitcherContext } from "../context/languageSwitcherContext"
import { Image } from "@theme-ui/components"
import { ChevronDown, ChevronUp, Menu, X } from "react-feather"
import { InboundLink } from "./link"
import { motion, AnimatePresence } from "framer-motion"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import Logo from "../images/logo.svg"
import * as Scroll from "react-scroll"
const LinkScroll = Scroll.Link

const MobileNav = ({ theme = "light", pageSections }) => {
  const primary = getColor(themeUiTheme, "primary")
  const dark = getColor(themeUiTheme, "dark")
  const light = getColor(themeUiTheme, "light")
  const [show, setShow] = useState(false)
  const locale = React.useContext(LanguageSwitcherContext).activeLocale
  const menu = pageSections

  const productFamily = undefined
  const productCategory = undefined

  const sideVariants = {
    closed: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: 1,
      },
    },
  }

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <motion.div
        initial={{
          position: "fixed",
          backgroundColor: light,
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          zIndex: 1000,
          overflowY: "auto",
          minHeight: "100px",
        }}
        animate={{
          height: show ? "100%" : "100px",
        }}
        transition={{ type: "linear", stiffness: 1, delayChildren: 1 }}
      >
        <Container
          sx={{
            display: "flex",
            height: "100px",
            alignItems: "center",
            backgroundColor: "light",
            paddingY: [2, 3],
          }}
        >
          <Flex
            sx={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Box>
                <InboundLink to={getHomePath(locale)}>
                  <Image src={Logo} sx={{ width: "100px" }} />
                </InboundLink>
              </Box>
            </Box>
            <Box sx={{ mt: [2] }} onClick={() => setShow(!show)}>
              <Hamburger show={show} />
            </Box>
          </Flex>
        </Container>
        <Box sx={{ px: [0, 0, 0, 3], py: [0, 0], display: show ? "block" : "none" }}>
          <Flex
            sx={{
              flexDirection: "column",
              padding: 0,
              margin: 0,
              listStyle: "none",
            }}
            as="ul"
          >
            {menu &&
              menu.map((item, index) => (
                <TextComponent
                  item={item}
                  showNav={show}
                  setShow={setShow}
                  locale={locale}
                  key={item.id}
                  theme={theme}
                  index={index}
                  productFamily={productFamily}
                  productCategory={productCategory}
                />
              ))}
          </Flex>
        </Box>
      </motion.div>
    </Box>
  )
}

const Hamburger = ({ show }) => {
  return (
    <Box
      className={show ? "show" : ""}
      sx={{
        width: "25px",
        "&:before,&:after,& div": {
          borderRadius: "sm",
          content: "''",
          backgroundColor: "primary",
          display: "block",
          height: "3px",
          my: [1],
          transition: ".55s",
        },
        "&.show:before": {
          transform: "translateY(8px) rotate(135deg)",
        },
        "&.show:after": {
          transform: "translateY(-6px) rotate(-135deg)",
        },
        "&.show div": {
          transform: "scale(0)",
        },
      }}
    >
      <Box></Box>
    </Box>
  )
}

const TextComponent = ({
  item,
  locale,
  showNav,
  setShow,
  theme,
  productFamily,
  productCategory,
  index,
}) => {
  const componentTheme = theme || "light"

  return (
    <Box
      as="li"
      key={item.id}
      sx={{
        position: "relative",
        fontWeight: "normal",
        color: "dark",
        borderColor: "rgba(151, 151, 151,.5)",
        mt: ["-1px"],
        a: {
          color: "primary",
          width: "100%",
          display: "inline-block",
          fontSize: [4],
          textDecoration: "none",
        },
        "&:last-child": {
          ul: {
            right: 0,
          },
        },
        "& > a,& > div a": {
          color: "dark",
          paddingX: 3,
          paddingY: [2],
          display: "block",
        },
        "a.active": {
          color: "primary",
        },
      }}
      onClick={() => setShow(false)}
    >
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: showNav ? 1 : 0,
          transition: {
            opacity: {
              delay: showNav ? index * 0.09 : 0,
            },
          },
        }}
      >
        <Flex
          sx={{
            cursor: "default",
            paddingY: [2, 2],
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LinkScroll
            onClick={() => setShow(false)}
            spy={false}
            smooth={true}
            duration={500}
            offset={-120}
            to={`${item.id}`}
          >
            {item.title}
          </LinkScroll>
        </Flex>
      </motion.div>
    </Box>
  )
}

export default MobileNav
